import * as React from "react"
import Layout from "../components/layout"
import HomeChiSiamo from "../components/home_chisiamo";

import { ParallaxProvider } from 'react-scroll-parallax';

import LeafletMap from "../components/leafletmap"


const ContattiPage = ({location}) => {

  return (
    <Layout location={location} title=''>
      
      <ParallaxProvider>

      <HomeChiSiamo />
      
      <section className="bg-white pb-40 relative z-20">
        <h1 className="text-black text-center pb-20 relative top-10 tracking-wider text-4xl">Contattaci</h1>
        <section className="w-6/6 md:w-4/6 container">
            <div className="grid">
                <div className=" py-20 px-20">
                    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Studio125 contact form">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="Studio125 contact form" />
                        <div className="">
                            <div className="grid md:grid-cols-2 gap-4">
                                <label className="mt-5">
                                    Nome*
                                    <input type="text" name="name" id="name" className="w-full mb-5 p-5 border-b-2 focus:outline-none focus:ring focus: border-black" />
                                </label>
                                <label className="mt-5">
                                    Email*
                                    <input type="email" name="email" id="email" className="w-full mb-5 p-5 border-b-2 focus:outline-none focus:ring focus: border-black " />
                                </label>
                            </div>
                            <label className="mt-5">
                            Messaggio:
                                <textarea name="message" id="message" rows="5" className="w-full mb-5 p-5 border-b-2 focus:outline-none focus:ring focus: border-black" />
                            </label>
                            <button type="submit" className="ml-0 py-5 px-10 my-10 font-sans font-bold text-black uppercase tracking-widest border-solid border-2 border-black transform duration-300 hover:scale-101">Invia</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <section>
        {typeof window !== 'undefined' &&
        <LeafletMap
          position={[40.85678298874334, 14.267168124981865]}
          zoom={18}
          markerText={"Studio125"}
        />
        }
        <div className="p-10 text-center">
            <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/yg8xb3JV9TTwPz7k6">View on Google Maps</a>
        </div>
        </section>
      </section>
      

      </ParallaxProvider>
      
    </Layout>
  )
}

export default ContattiPage

